import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The Boron Letters Review – Gary Halbert",
  "author": "tzookb",
  "type": "post",
  "date": "2019-01-19T17:53:06.000Z",
  "url": "/2019/01/the-boron-letters-review-gary-halbert/",
  "categories": ["Book Reviews"],
  "tags": ["books", "copywriting", "marketing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Gary C. Halbert is known for the best copywriter of all times. Any copywriting course, post, the book will in some way mention his name and may refer you to his `}{`“`}{`Boron Letters`}{`”`}{`. These are the letters Gary has sent his son Bond from prison. The letters will share some golden nuggets on marketing, copywriting and simple life hacks from his experience. You would learn that life aren`}{`’`}{`t that easy and you should be tough in all times. It would be a good idea to grow your biceps and throw away the losers from your life. `}<strong parentName="p">{`Some health tips:`}</strong>{` exercise every day (warmup at a minimum), do at least one fasting day per week and eat a lot of veggies and fruits. `}<strong parentName="p">{`Life tips:`}</strong>{` dont be emotional, disconnect from people who try to get you down, or hold in your place. Respond on an emotional message within 2 days at least. Keep on learning all the time. Dont hold a grudge. `}<strong parentName="p">{`Marketing tips:`}</strong>{` learn your customers, learn the market, learn their pains. Look for a market that needs something then create a product not the opposite way around. Be unique catch attention. Copywriting tips: write to one person, just like you are talking to him. reread your copy out loud, fix any hiccups to make your copy smooth. Make your copy readable, have spaces, brackets, bolds etc. Make your copy create an image in the client`}{`’`}{`s mind. show him the image of his life after using your product. Walk him through the purchase, don`}{`’`}{`t give him the need to think, make it plain simple to purchase.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      